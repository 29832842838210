/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:ddb88ad3-fbc2-4e8a-bd5a-e9126b9b6fa1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_sSXKQ74bg",
    "aws_user_pools_web_client_id": "7q674tski4iu7pl6mbtlg0ft8j",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://mmp3fagss1.execute-api.eu-west-1.amazonaws.com/main",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://r6et6e5b5zhtteszh5j77hzxfe.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
